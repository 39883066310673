import { post } from '@/utils/http'


// 签到日历
export const signInCalendar = function (data) {
  return post({
      url: "/app/signin/details/signInCalendar",
      data
  })
}

// 补签
export const extraSignIn = function (data) {
  return post({
      url: "/app/signin/details/extraSignIn",
      data
  })
}

// 签到
export const addSignIn = function (data) {
  return post({
      url: "/app/signin/details/addSignIn",
      data
  })
}

// 根据用户ID获取用户的签到信息
export const getSignInInfo = function (data) {
  return post({
      url: "/app/signin/users/getSignInInfo",
      data
  })
}

// 任务中心签到入口
export const signInEntry = function (data) {
  return post({
      url: "/app/signin/details/signInEntry",
      data
  })
}

// 我的任务列表
export const selectMyTaskList = function (data) {
  return post({
      url: "/app/activity/task/selectMyTaskList",
      data
  })
}

// 我的收益
export const getMyAccount = function (data) {
  return post({
      url: "/app/activity/task/getMyAccount",
      data
  })
}

// 我的收益列表
export const getUserAccountRecordList = function (data) {
  return post({
      url: "/app/activity/task/getUserAccountRecordList",
      data
  })
}
// 获取签到设置信息
export const getSetInfo = function (data) {
  return post({
      url: "/app/signin/set/getSetInfo",
      data
  })
}