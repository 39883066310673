<template>
    <div class="explain-dialog re_flex_center" v-if="visible">
        <div class="explain-content re_sizing re_relative">
            <!-- 关闭按钮 -->
            <div class="close-btn re_absolute" @click="handleClose">
                <img src="https://tp.kaishuihu.com/dsgc/icon/static/image/image/close.png" alt="关闭">
            </div>
            
            <!-- 标题 -->
            <div class="re_font_18 re_font_bold re_text_c re_margin_b_24">
                {{title}}
            </div>

            <!-- 规则列表 -->
            <div class="rules-list">
                <div class="rule-item re_margin_b_16" v-html="rules"></div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'ExplainDialog',
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: '规则说明'
        }
    },
    emits: ['update:visible'],
    setup(props, { emit }) {

        const handleClose = () => {
            emit('update:visible', false)
        }

        return {
            handleClose
        }
    }
}
</script>

<style scoped lang="less">
.explain-dialog {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.explain-content {
    width: 315px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 24px 20px;

    .close-btn {
        right: 16px;
        top: 24px;
        width: 24px;
        height: 24px;
        img {
            width: 100%;
            height: 100%;
        }
    }

    .rules-list {
        max-height: 60vh;
        overflow-y: auto;
        padding: 0 4px;
        margin-bottom: 24px;
        margin-top: 12px;

        &::-webkit-scrollbar {
            display: none;
        }
    }

}
</style> 