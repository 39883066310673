<template>
  <div class="success-popup re_flex_center" v-if="visible">
    <div class="popup-overlay" @click="closePopup"></div>
    <div class="popup-content re_flex_center">
      <img class="success-icon re_margin_bot_16" src="../../../../assets/task/success.png" alt="">
      <div class="re_margin_bot_8 re_font_16 re_color_white">{{ title }}</div>
      <div class="re_margin_bot_8 re_font_14 re_color_white">金币 <span class="color_redlight"> +{{ rewardAmount }}</span> </div>
      <div class="streak-text re_font_12 re_color_light">已连续签到{{ streakDays }}天</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckinSuccessPopup',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    rewardAmount: {
      type: Number,
      default: 30
    },
    streakDays: {
      type: Number,
      default: 1
    },
    title: {
      type: String,
      default: '签到成功'
    }
  },
  emits: ['close:visible'],
  methods: {
    closePopup() {
      this.$emit('close:visible', false);
    }
  }
}
</script>

<style scoped>
.success-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popup-content {
  position: relative;
  width: 240px;
  height: 240px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 12px;
  flex-direction: column;
}

.success-icon {
  width: 64px;
  height: 64px;
}

.color_redlight{
    color: #FFE28B;
}
</style>
