<template>
    <div class="task-center" ref="scrollContainer" :class="{ 'padding-top': userStore.isPadTop }">
        <div class="title re_relative re_flex_center re_font_18 re_bg_white" :class="{ 'is-pad-top': userStore.isPadTop }">
            <van-icon @click="goBack()" class="icon" name="arrow-left" size="22" />
            <span>任务中心</span>
        </div>
      <van-pull-refresh v-model="isRefreshing" @refresh="onRefresh">
        <!-- 用户资产信息 -->
        <div class="user-assets re_color_white">
            <div class="re_flex">
                <div class="asset-item" @click="goIncome('coins')">
                    <div class="re_font_14">
                        <span class="re_font_family">我的金币</span>
                        <van-icon name="arrow" />
                    </div>
                    <div class="re_font_24 re_margin_top_8 re_font_bold">
                        <van-rolling-text :height="24" class="rolling-text" ref="rollingText" :auto-start="false" :start-num="userStore.oldIcon" :target-num="incomeInfo.userIcons || 0" direction="up" :duration="1" />
                    </div>
                </div>
                <div class="asset-item minWidth" @click="goIncome('points')">
                    <div class="re_font_14">
                        <span class="re_font_family">我的溜溜积分</span>
                        <van-icon name="arrow" />
                    </div>
                    <div class="re_font_24 re_margin_top_8 re_font_bold">
                        <span class="re_margin_r_8">{{ incomeInfo.lzBalance || 0 }}</span>
                        <img @click.stop="goCashOuts" class="cashOuts" src="../../../assets/task/cashOuts.png" alt="">
                    </div>
                    
                </div>
            </div>
            <div class="re_font_12 intro">每日0点自动兑换成溜溜积分</div>
            <div @click="goShop" class="re_text_c score re_relative re_margin_top_8">
                <span class="re_font_14 re_color_white">积分商城</span>
                <!-- <img class="icon re_absolute" src="../../../assets/task/icon.png" alt=""> -->
            </div>
        </div>
        <div v-if="!isShowUserAssets" :style="{ opacity: isOpacity }" class="fixed-title re_font_14 re_color_light" :class="{ 'isFixedTop': userStore.isPadTop }">
            <div class="fixed-item">金币 
                <span class="re_font_16 re_color_light_2 re_font_bold re_margin_l_4">
                    <van-rolling-text class="rolling-text-top" ref="rollingTextTop" :auto-start="false" :start-num="userStore.oldIcon" :target-num="incomeInfo.userIcons || 0" direction="up" :duration="1" />
                </span>
            </div>
            <div class="fixed-item">溜溜积分 <span class="re_font_16 re_color_light_2 re_font_bold re_margin_l_4">{{ incomeInfo.lzBalance || 0 }}</span></div>
            <div class="re_color_light_2 fixed-item-end" @click="getScrollTop()">详情 <img class="icon-down" src="../../../assets/task/triangle.png" alt=""></div>
        </div>
        <!-- 每日签到 -->
        <div class="sign_box" ref="signBox">
            <div class="re_flex_between" v-if="signInfo">
                <div>
                    <div>
                        <span class="re_font_16 re_font_bold">每日签到</span>
                        <img class="star re_margin_l_8 re_relative" src="../../../assets/task/star.png" alt="">
                        <span class="re_font_16 re_color_gold re_margin_l_8 re_relative number">{{ signInfo.todayMoney || 50 }}</span>
                    </div>
                    <div class="re_font_14 re_margin_top_8">
                        <span v-if="signInfo.continuousDays" class="re_color_normal re_margin_r_8">已连签{{ signInfo.continuousDays }}天</span>
                        <span class="re_color_light"> {{ signInfo.todaySignInStatus == 2 ? '明日' : '今日' }}签到得{{ signInfo.todaySignInStatus == 2 ? signInfo.tomorrowMoney : signInfo.todayMoney }}金币</span>
                    </div>
                </div>
                <button class="task-button re_color_white re_font_14 re_flex_center" :class="{ completed: tasks.dailySignIn.completed }"
                    @click="handleSign('dailySignIn')">
                    {{ signInfo.todaySignInStatus == 2 ? '查看日历' : '立即签到' }}
                </button>
            </div>
            <div class="sign-in-days">
                <div v-for="(day, index) in signInfo.list" :key="index" class="day-item re_sizing re_text_c">
                    <div class="bu" v-if="day.signInStatus == 2" @click="goCalendar()">
                        <img class="bu" src="../../../assets/task/yes.png" alt="">
                    </div>
                    <div class="bu" v-else-if="day.signInStatus == 3" @click="goCalendar()">
                        <img class="bu" src="../../../assets/task/no.png" alt="">
                    </div>
                    <div v-else-if="day.signInStatus == 0 && index > 0" class="yesbox bu re_sizing re_text_c re_font_12" @click="goCalendar()">
                        <img class="star" src="../../../assets/task/star.png" alt="">
                        <div class="yes">+{{ day.getMoney }}</div>
                    </div>
                    <div @click="handleSign('dailySignIn')" v-else-if="day.signInStatus == 0 && index == 0" class="today yesbox bu re_sizing re_text_c re_font_12">
                        <img class="star" src="../../../assets/task/star.png" alt="">
                        <div class="yes">+{{ day.getMoney }}</div>
                    </div>
                    <span class="re_font_10 re_color_light">
                        {{ day.dataText }}
                    </span>
                </div>
            </div>
        </div>
        <!-- 日常任务列表 -->
        <div class="daily-tasks">
            <div class="re_font_16 re_font_bold re_margin_b_16">日常任务</div>
            <!-- 其他任务项 -->
            <div v-for="(task, index) in taskList" :key="index">
                <div :class="['task-item re_flex_between', task.taskPtype == 'activity_task_ptype_new_recall' && task.taskStype == 'activity_task_stype_new' ? 'border-none' : '']" >
                    <div class="re_flex re_flex_align re_flex_3">
                        <img class="task-icon re_margin_r_8" :src="task.taskIcon" alt="">
                        <div class="task-info">
                            <div class="task-name re_color_normal re_font_14 re_font_bold re_flex">
                                <div class="task-name-item">
                                    {{ task.taskTitle }}
                                    <span v-if="task.taskFactorItem.factorQuantity > 1">({{task.redunField ? task.redunField : 0}}/{{ task.taskFactorItem.factorQuantity }})</span>
                                </div>
                                <div style="min-width: 50px;">
                                    <img class="star re_relative" src="../../../assets/task/star.png" alt="">
                                    <span class="re_color_gold re_font_14 re_margin_l_8">{{ task.taskRewardCoins }}</span>
                                </div>
                            </div>
                            <div class="re_color_light re_font_12">{{ task.taskDesc }}</div>
                        </div>
                    </div>
                    <button class="task-button re_color_white re_font_14 re_flex_center" :class="{ completed: task.status }" @click="handleTask(task)">
                        {{ task.status == 1 ? '已完成' : task.taskNoCompleteWare }}
                    </button>
                </div>
                <div class="re_flex re_flex_align inviteFriends re_margin_l_32" v-if="task.taskPtype == 'activity_task_ptype_new_recall' && task.taskStype == 'activity_task_stype_new'">
                    <div v-if="task.redunField" v-for="(item, index) in task.redunField.newRegisterUsers" :key="index" class="re_font_10 re_color_light re_text_c user">
                        <img class="avatar" :src="item.photo" alt="">
                        <div class="re_ellipsis nickname re_text_c">{{item.nickname}}</div>
                    </div>
                    <div v-for="item in (task.taskFactorItem.factorQuantity -  (task.redunField ? (task.redunField.newRegisterUsers.length) : 0))" :key="item" class="re_font_10 re_color_light re_text_c user">
                        <img class="avatar" src="../../../assets/task/add.png" alt="" @click="handleTask(task)">
                        <div>待邀请</div>
                    </div>
                </div>
            </div>
        </div>
      </van-pull-refresh>
        <!-- 查看活动规则 -->
        <div class="re_font_14 re_color_light re_text_c rules" @click="showRules">
            查看活动规则 >
        </div>
        <success :visible="showSuccess" @close:visible="showSuccess = $event" :rewardAmount="handleSignInfo.signMoney" :streakDays="handleSignInfo.continuousDays"></success>
        <getMoney :goldenAmount="incomeInfo.goldenAmount" :visible="showMoney" :tipType="incomeInfo.tipType" @update:visible="showMoney = $event"></getMoney>
        <explain :visible="showExplain" :rules="incomeInfo.activityRule" :title="'活动规则'" @update:visible="showExplain = $event"></explain>
    </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { selectMyTaskList, getMyAccount, signInEntry, addSignIn } from './api'
import success from './components/success.vue'
import getMoney from './components/getMoney.vue'
import Explain from './components/explain.vue'
import { showDialog } from 'vant';
import { router } from '../../router';
import { useUserStore } from '../../stores/user';
export default {
    name: 'TaskCenter',
    components:{
        success,
        getMoney,
        Explain
    },
    
    setup() {
        const isRefreshing = ref(false);
        async function onRefresh() {
            getSignInEntry();
            getDrawList();
            isRefreshing.value = false;
        }
        const goBack = () => {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
            let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

            if (isAndroid) {
                android.finish()
            }
            if (isiOS) {
                window.webkit.messageHandlers.AppModel.postMessage('finish')
            }
        }
        const userAssets = ref({
            coins: 10000,
            points: 100
        })
        const showSuccess = ref(false);
        const handleSignInfo = ref({});
        const handleSign = async () => {
            if(signInfo.value.todaySignInStatus == 2){
                router.push('/calendar')
            }else{
                const res = await addSignIn()
                if(res.code == 1){
                    showSuccess.value = true;
                    handleSignInfo.value = res.data;
                    setTimeout(() => {
                        showSuccess.value = false;
                    }, 3000);
                    getDrawList();
                    getSignInEntry();
                } else {
                    showDialog({ title: '提示', message: res.msg });
                }
            }
        }
        const goCalendar = () => {
            if(signInfo.value.todaySignInStatus == 2) {
                router.push('/calendar')
            }
        }
        const goShop = () => {
            router.push('/taskCenter/scoreShop')
        }
        const goIncome = (type) => {
            router.push('/taskCenter/income?type=' + type)
        }

        const taskList = ref([])

        const tasks = ref({
            dailySignIn: {
                completed: false
            }
        })

        const showExplain = ref(false)
        
        const handleTask = async (task) => {
            if(task.status == 1) return
            sessionStorage.setItem('taskList', JSON.stringify(task));
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
            let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isAndroid) {
                android.toCompleteTask(JSON.stringify({
                    taskPtype: task.taskPtype,
                    taskStype: task.taskStype,
                    taskFactor: task.taskFactor
                }))
            }
            if (isiOS) {
                window.webkit.messageHandlers.AppModel.postMessage({
                    name: 'toCompleteTask',
                    taskPtype: task.taskPtype,
                    taskStype: task.taskStype,
                    taskFactor: task.taskFactor
                })
            }
        }

        const showRules = () => {
            // 实现查看规则的逻辑
            showExplain.value = true
        }

        const handleShowExplain = () => {
            showExplain.value = true
        }

        const signInfo = ref({
            continuousDays: 0,
            todayMoney: 0,
            list: []
        })
        const getSignInEntry = async () => {
            const res = await signInEntry()
            res.data.list.forEach(item => {
                const dateObj = item.signInDay.split('-')
                item.dataText = getDayText(dateObj)
            })
            signInfo.value = res.data
        }
        const incomeInfo = ref({})
        const rollingText = ref('')
        const rollingTextTop = ref('')
        const showMoney = ref(false);
        const getDrawList = async () => {
            const res = await selectMyTaskList()
            const res2 = await getMyAccount()
            res.data.forEach(item => {
                item.taskFactorItem = JSON.parse(item.taskFactorItem)
                item.redunField = JSON.parse(item.redunField)
            })
            userStore.oldIcon = userStore.oldIcon > 0 ? incomeInfo.value.userIcons || userStore.oldIcon : incomeInfo.value.userIcons || res2.data.userIcons;
            taskList.value = res.data
            incomeInfo.value = res2.data
            showMoney.value = incomeInfo.value.isTip;
            if (showMoney.value) {
                setTimeout(() => {
                    showMoney.value = false;
                }, 3000);
            }
            rollingText.value.reset();
            !isShowUserAssets.value ? rollingTextTop.value.reset() : '';
            if (userStore.oldIcon !== incomeInfo.value.userIcons) {
                setTimeout(() => {
                    rollingText.value.start();
                    !isShowUserAssets.value ? rollingTextTop.value.start() : '';
                }, 100);
                setTimeout(() => {
                    userStore.oldIcon = incomeInfo.value.userIcons;
                    rollingText.value.reset();
                    !isShowUserAssets.value ? rollingTextTop.value.reset() : '';
                }, 1000);
            }
        }
        const formatTime = (time) => {
            return time < 10 ? '0' + time : time
        }
        const getDayText = (dateObj) => {
            const today = new Date();
            const todayYear = today.getFullYear();
            const todayMonth = formatTime(today.getMonth() + 1);
            const todayDay = formatTime(today.getDate());
            
            // 检查是否是今天
            if (dateObj[0] == todayYear && dateObj[1] == todayMonth && dateObj[2] == todayDay) {
                return "今天";
            }
            
            // 检查是否是明天
            const tomorrow = new Date(today);
            tomorrow.setDate(today.getDate() + 1);
            const tomorrowYear = tomorrow.getFullYear();
            const tomorrowMonth = formatTime(tomorrow.getMonth() + 1);
            const tomorrowDay = formatTime(tomorrow.getDate());
            
            if (dateObj[0] == tomorrowYear && dateObj[1] == tomorrowMonth && dateObj[2] == tomorrowDay) {
                return "明天";
            }
            
            // 其他日期显示月.日格式
            return `${dateObj[1]}.${dateObj[2]}`;
        }
        const userStore = useUserStore();
        const goCashOuts = () => {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
            let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isAndroid) {
                android.goCashOuts()
            }
            if (isiOS) {
                window.webkit.messageHandlers.AppModel.postMessage('goCashOuts');
            }
        }
        const scrollContainer = ref(null);
        const isShowUserAssets = ref(true);
        const signBox = ref(null);
        const scollTop = ref(0);
        const isOpacity = computed(() => {
            return (scollTop.value - 120)/60
        })
        const scrollHandler = (event) => {
            const signBoxlist = signBox.value.getBoundingClientRect().top
            if (signBoxlist < 150) {
                isShowUserAssets.value = false;
            }else if (signBoxlist > 130) {
                isShowUserAssets.value = true;
            }
            scollTop.value = event.target.scrollTop
        }
        const getScrollTop = () => {
            scrollContainer.value.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
        onMounted(async () => {
            getSignInEntry()
            getDrawList()
            window.onRefreshInit = function(){
                getSignInEntry()
                getDrawList()
            };
            scrollContainer.value.addEventListener('scroll', scrollHandler);
            let u = navigator.userAgent;
            let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if(isiOS){
                userStore.isPadTop = true;
            }
        })

        return {
            isShowUserAssets,
            scrollContainer,
            signBox,
            getScrollTop,
            isOpacity,
            userStore,
            isRefreshing,
            onRefresh,
            userAssets,
            showSuccess,
            taskList,
            tasks,
            handleTask,
            formatTime,
            showRules,
            showExplain,
            showMoney,
            signInfo,
            handleSignInfo,
            handleShowExplain,
            getDayText,
            handleSign,
            goCalendar,
            goShop,
            rollingText,
            rollingTextTop,
            incomeInfo,
            goIncome,
            goBack,
            goCashOuts
        }
    }
}
</script>

<style scoped lang="less">
.task-center {
    padding: 80px 16px 16px;
    background: #fff;
    height: calc(100vh - 96px);
    overflow-y: auto;
    &.padding-top{
        padding-top: 96px;
    }
    .title {
        padding: 30px 0 18px 0;
        width: 100%;
        font-weight: 600;
        position: fixed;
        z-index: 10;
        left: 0;
        top: 0;
        .icon {
            position: absolute;
            left: 16px;
            bottom: 18px;
        }
    }
    .is-pad-top {
        padding-top: 46px;
    }
    .fixed-title {
        position: fixed;
        z-index: 100;
        left: 0;
        top: 60px;
        width: calc(100vw - 32px);
        padding: 0 16px;
        height: 44px;
        background: #fff;
        display: flex;
        justify-content: start;
        align-items: center;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
        .fixed-item {
            min-width: 20%;
            margin-right: 16px;
        }
        .fixed-item-end {
            margin-left: auto;
        }
    }
    .isFixedTop {
        top: 76px;
    }
    .icon-down{
        width: 12px;
        height: 9px;
    }
}

.user-assets {
    background-image: linear-gradient(136deg, #EE626F 0%, #EC5781 100%);
    border-radius: 8px;
    padding: 20px 0 12px 0;
    position: relative;
    margin-bottom: 16px;
    .score{
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        padding-top: 16px;
    }
    .cashOuts{
        width: 44px;
        height: 20px;
        position: absolute;
        top: 5px;
    }
    .intro{
        color: rgba(255, 255, 255, 0.7);
        padding: 0 20px;
    }
}
.sign_box{
    background: #FFF5F5;
    border-radius: 8px;
    padding: 18px 12px 30px 12px;
    margin-bottom: 16px;
    // height: 174px;
    .star{
        width: 16px;
        height: 16px;
        top: 2px;
    }
    .number{
        left: -4px;
    }
}

.asset-item {
    margin-bottom: 12px;
    min-width: 30%;
    padding: 0 20px;
    &.minWidth{
        min-width: calc(30% + 44px);
    }
    > div {
        position: relative;
    }
}
.rolling-text{
    --van-rolling-text-color: white;
    --van-rolling-text-font-size: 24px;
    --van-rolling-text-line-height: 24px;
    --van-rolling-text-height: 24px;
}
.rolling-text-top {
    --van-rolling-text-font-size: 16px;
    --van-rolling-text-color: #1d1d2c;
    --van-rolling-text-item-width: 10px;
}

.daily-tasks {
    background: white;
    border-radius: 8px;
    padding: 8px 4px 16px 4px;
    .task-icon{
        width: 36px;
        height: 36px;
    }
    .inviteFriends{
        width: 298px;
        min-height: 68px;
        background: #F5F7F9;
        border-radius: 8px;
        flex-wrap: wrap;
    }
}

.border-none{
    border-bottom: none !important;
}
.border-bottom{
    padding-bottom: 16px;
    border-bottom: 1px solid #F5F7F9;
}
.task-item {
    padding: 18px 0;
    border-bottom: 1px solid #F5F7F9;

}
.user{
    width: 20%;
    padding: 4px 0;
    .avatar{
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
    .nickname{
        max-width: 80px;
    }
}

.checkbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ddd;
    margin-right: 12px;
}

.checkbox.active {
    background: #ff6b81;
    border-color: #ff6b81;
}

.task-info {
    flex: 1;
}

.task-name {
    margin-bottom: 4px;
    line-height: 20px;
    .star{
        width: 16px;
        height: 16px;
        top: 2px;
        left: 2px;
    }
    .task-name-item {
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}


.sign-in-days {
    display: flex;
    gap: 4px;
    margin: 16px 0 0 0;
    .star{
        width: 16px;
        height: 16px;
        top: 2px;
    }
    .yesbox{
        padding-top: 8px;
        .yes{
            color: #FF455E;
            margin-top: 6px;
        }
    }
}
.day-item {
    background: #F5F7F9;
    border-radius: 4px;
    width: 42px;
    height: 54px;
    .bu{
        width: 42px;
        height: 54px;
    }
    .today {
        background: #fff;
        border-radius: 6px;
        border: 1px solid #FF455E;
    }
}

.task-button {
    min-width: 72px;
    height: 32px;
    border-radius: 18px;
    border: none;
    background: #FF455E;
}

.task-button.completed {
    background: #F5F7F9;
    color: #CED1D9;
}
.rules{
    padding-bottom: 16px;
}
</style>
