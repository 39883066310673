<template>
    <div class="signature-dialog re_flex_center" v-if="visible && tipType" @click="handleCancel">
        <div class="signature-content re_sizing re_relative re_text_c" @click.stop>
             
           <div v-if="tipType == 1">
                <div class="re_font_20 re_font_bold color_title">昨天收益</div>
                <div class="re_font_bold color_red re_margin_top_16">
                    <span class="number">{{ goldenAmount }}</span>
                    <span class="re_font_16">金币</span>
                </div>
                <div class="re_font_14 color_title re_margin_top_24">做任务轻松赚钱，继续加油</div>
           </div>
           <div class="re_margin_top_16" v-else-if="tipType == 2">
                <div class="re_font_20 color_red">又有{{ goldenAmount }}金币到账啦！</div>
                <div class="re_font_14 color_title re_margin_top_16">做任务轻松赚钱，继续加油</div>

                <div class="re_font_16 re_flex_center re_color_white get_money_btn" @click="handleCancel">开心收下</div>
           </div>
        </div>
    </div>   
</template>

<script>
export default {
    name: 'SignatureDialog',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        tipType: {
            type: Number,
            default: null
        },
        goldenAmount: {
            type: Number,
            default: 0
        }
    },
    emits: ['update:visible', 'confirm', 'cancel'],
    setup(props, { emit }) {
        const handleConfirm = () => {
            emit('confirm')
            emit('update:visible', false)
        }

        const handleCancel = () => {
            emit('cancel')
            emit('update:visible', false)
        }

        return {
            handleConfirm,
            handleCancel
        }
    }
}
</script>

<style scoped lang="less">
.signature-dialog {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 12px;
    overflow: hidden;
    z-index: 100;

    .van-dialog__content {
        padding: 24px 16px;
    }
}

.signature-content {
    width: 262px;
    height: 380px;
    background: url('../../../../assets/task/giftTaskBg.png') no-repeat center center;
    background-size: 100% 100%;
    border-radius: 16px;
    padding-top: 206px;
    .button-group {
        border-top: 1px solid #F5F7F9;
        // padding: 12px 0 20px 0;
        height: 42px;
        line-height: 42px;
        gap: 12px;
        left: 0;
        bottom: 0px;
        width: 100%;

        div{
            width: 50%;
        }
        div:last-child{
            border-left: 1px solid #F5F7F9;
        }
    }
    .number{
        font-size: 40px;
    }
}
.get_money_btn{
    width: 179px;
    height: 40px;
    background-image: linear-gradient(90deg, #FD3C46 0%, #FF9255 99%);
    border-radius: 20px;
    margin: 20px auto 0;
}
.color_title{
    color: #5C0004;
}
.color_red{
    color: #FC5C63;
}
</style>
